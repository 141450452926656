.commonPageNav[data-v-6173d749] {
  padding-top: 1rem;
}
.commonPageNav .banner[data-v-6173d749] {
  height: 3.5rem;
  width: 100%;
  background-size: 100% 100%;
  position: relative;
}
.commonPageNav .banner[data-v-6173d749]::after {
  width: 50%;
  height: 100%;
  content: "";
  display: block;
  background-image: var(--lineargradient);
}
.commonPageNav .banner .titlebox[data-v-6173d749] {
  position: absolute;
  top: 1.3rem;
  left: 1.9rem;
}
.commonPageNav .banner .titlebox .title[data-v-6173d749] {
  font-size: 0.48rem;
  color: #ffffff;
  margin-bottom: 0.12rem;
}
.commonPageNav .banner .titlebox .desc[data-v-6173d749] {
  font-size: var(--f18);
  color: #ffffff;
}
.commonPageNav .bread[data-v-6173d749] {
  font-size: var(--f16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f5f5;
}
.commonPageNav .bread-left[data-v-6173d749] {
  padding: 0.28rem 0.4rem 0.28rem 1.9rem;
  background: #efefef;
}
.commonPageNav .bread-left .breadnumd[data-v-6173d749] {
  color: #8a8a96;
  margin-right: 0.18rem;
  cursor: pointer;
}
.commonPageNav .bread-left .breadnumd .icon[data-v-6173d749] {
  color: #8a8a96;
  margin-right: 0.08rem;
}
.commonPageNav .bread-left .breadnumd[data-v-6173d749]:last-child {
  color: var(--comthemeColor);
}
.commonPageNav .bread .aboutnav[data-v-6173d749] {
  padding-right: 1.4rem;
}
.commonPageNav .bread .aboutnav-item[data-v-6173d749] {
  display: inline-block;
  color: #8a8a96;
  margin-right: 0.5rem;
  cursor: pointer;
}
.commonPageNav .bread .aboutnav-itemactive[data-v-6173d749] {
  color: var(--comthemeColor);
  border-bottom: 0.01rem solid var(--comthemeColor);
  padding: 0.27rem;
}