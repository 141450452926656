.detail {
  padding-top: 1rem;
}
.detail .banner {
  height: 3.5rem;
  width: 100%;
  background-size: 100% 100%;
  position: relative;
}
.detail .banner::after {
  width: 50%;
  height: 100%;
  content: "";
  display: block;
  background-image: var(--lineargradient);
}
.detail .banner .titlebox {
  position: absolute;
  top: 1.3rem;
  left: 1.9rem;
}
.detail .banner .titlebox .title {
  font-size: 0.48rem;
  color: #ffffff;
  margin-bottom: 0.12rem;
}
.detail .banner .titlebox .desc {
  font-size: var(--f18);
  color: #ffffff;
}
.detail .bread {
  font-size: var(--f16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f5f5;
}
.detail .bread-left {
  padding: 0.28rem 0.4rem 0.28rem 1.9rem;
  background: #efefef;
}
.detail .bread-left .breadnumd {
  color: #8a8a96;
  margin-right: 0.18rem;
  cursor: pointer;
}
.detail .bread-left .breadnumd .icon {
  color: #8a8a96;
  margin-right: 0.08rem;
}
.detail .bread-left .breadnumd:last-child {
  color: var(--comthemeColor);
}
.detail .bread .aboutnav {
  padding-right: 1.4rem;
}
.detail .bread .aboutnav-item {
  display: inline-block;
  color: #8a8a96;
  margin-right: 0.5rem;
  cursor: pointer;
}
.detail .bread .aboutnav-itemactive {
  color: var(--comthemeColor);
  border-bottom: 0.01rem solid var(--comthemeColor);
  padding: 0.27rem;
}
.detail .container {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-left {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 0.65rem;
  border-right: 0.01rem solid #ebebeb;
}
.detail .container .detail-left .swarticle .title {
  font-size: 0.36rem;
  color: #191c22;
  font-weight: bold;
}
.detail .container .detail-left .swarticle .author {
  font-size: var(--f18);
  color: #666666;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.detail .container .detail-left .swarticle .content {
  margin-bottom: 0.4rem;
  font: initial;
  font-size: var(--f18);
  line-height: 0.32rem;
  text-indent: 0.36rem;
}
.detail .container .detail-left .swarticle .content video {
  width: 100% !important;
}
.detail .container .detail-left .swarticle .content img {
  width: 100% !important;
  height: 100% !important;
}
.detail .container .detail-left .swarticle .content strong {
  font-weight: bold !important;
}
.detail .container .detail-left .swarticle .content h1,
.detail .container .detail-left .swarticle .content h2,
.detail .container .detail-left .swarticle .content h3,
.detail .container .detail-left .swarticle .content h4,
.detail .container .detail-left .swarticle .content h5,
.detail .container .detail-left .swarticle .content h6 {
  font-weight: bold !important;
}
.detail .container .detail-left .swarticle .content table {
  width: calc(100% - 0.16rem) !important;
}
.detail .container .detail-left .share {
  margin-top: 0.1rem;
}
.detail .container .detail-left .share p {
  font-size: var(--f14);
  color: #8c94a3;
  margin-bottom: 0.12rem;
}
.detail .container .detail-left .share .sharecontent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item {
  margin-right: 0.12rem;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item img {
  width: 0.26rem;
  height: 0.26rem;
  padding: 0.12rem 0.12rem;
  border: 0.02rem solid #ebebeb;
  background-color: #ffffff;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item:hover img {
  background-color: var(--themeColor);
}
.detail .container .detail-left .share .sharecontent .return {
  color: #999999;
  font-size: var(--f14);
  width: 1.04rem;
  height: 0.54rem;
  border: 0.02rem solid #ededed;
  text-align: center;
  line-height: 0.54rem;
  cursor: pointer;
}
.detail .container .detail-left .next {
  margin-top: 0.6rem;
}
.detail .container .detail-left .next p {
  font-size: var(--f16);
  color: #3f4150;
  padding: 0.25rem 0.3rem;
  background-color: #ededed;
  margin-bottom: 0.1rem;
  cursor: pointer;
}
.detail .container .detail-right {
  width: 4.58rem;
  margin-left: 0.65rem;
}
.detail .container .detail-right h3 {
  font-size: var(--f24);
  font-weight: bold;
  color: #000000;
}
.detail .container .detail-right .hotlist-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #ebebeb;
  cursor: pointer;
}
.detail .container .detail-right .hotlist-item .pic {
  width: 2rem;
}
.detail .container .detail-right .hotlist-item-info {
  margin-left: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-right .hotlist-item-info .title {
  font-size: var(--f18);
  color: #000000;
}
.detail .container .detail-right .hotlist-item-info .time {
  margin-top: 0.4rem;
  font-size: var(--f16);
  color: #999999;
}